import { type CancellationPolicy } from '@shared/reservations/types';
import { type ApiResponse } from '@shared/types/apiHelpers';
import { type IconNameType } from '@shared/types/floorPlans';
import { type RestaurantStatus } from '@shared/types/restaurants';
import API from '../api/apiClient';

export interface RestaurantDetails {
  address: string;
  cancellationPolicy: CancellationPolicy | null;
  city: string;
  description: string;
  id: string;
  locationSlug: string;
  maxReservationGuests: number;
  name: string;
  nameSlug: string;
  phone: string;
  postalCode: string;
  schedule: number;
  state: string;
  status: RestaurantStatus;
  street: string;
  timezone: string;
  website: string;
}

export interface Restaurant {
  address: string;
  city: string;
  description: string;
  id: string;
  locationSlug: string;
  maxReservationGuests: 0;
  name: string;
  nameSlug: string;
  phone: string;
  postalCode: string;
  schedule: number;
  state: string;
  status: string;
  street: string;
  timezone: string;
  website: string;
}

export interface AvailabilityListing {
  iconName: IconNameType;
  id: string;
  isCommunal: boolean;
  publicName: string;
}

export interface Availability {
  isBiddable: boolean;
  isBuyable: boolean;
  guestCount: number;
  purchasePrice: number;
  listing: AvailabilityListing;
  time: string;
}

export interface RestaurantEvent {
  description: string;
}

export interface Closure {
  date: string;
  id: string;
  restaurantId: string;
}

export const getRestaurants = async (): Promise<ApiResponse<Restaurant[]>> => {
  const response = await API.get(`/restaurants`);
  return response.json();
};

export const getRestaurantDetailsBySlug = async (
  locationSlug: string,
  nameSlug: string,
): Promise<ApiResponse<RestaurantDetails>> => {
  const response = await API.get(`/restaurants/${locationSlug}/${nameSlug}`);
  return response.json();
};

export const getRestaurantDetailsById = async (
  restaurantId: string,
): Promise<ApiResponse<RestaurantDetails>> => {
  const response = await API.get(`/restaurants/${restaurantId}`);
  return response.json();
};

export const getAvailabilities = async (
  restaurantId: string,
  date: string,
): Promise<Availability[]> => {
  const response = await API.get(
    `/restaurants/${restaurantId}/availabilities/${date}`,
  );

  return response.json();
};

export const getEventForRestaurantIdAndDate = async (
  restaurantId: string,
  date: string,
): Promise<ApiResponse<RestaurantEvent>> => {
  const response = await API.get(`/restaurants/${restaurantId}/events/${date}`);

  return response.json();
};

export const getClosureForDate = async (
  restaurantId: string,
  date: string,
): Promise<Closure[]> => {
  const response = await API.get(`/closures/${restaurantId}?date=${date}`);

  return response.json();
};
