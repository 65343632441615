import cx from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { centsToWholeDollar } from '@utils/currency';
import { useError } from 'webReservations/hooks/useError';
import typography from '~styles/typography.scss';
import { useOfferCheckoutContext } from '../context/OfferCheckoutContext';
import { OFFER_CHECKOUT_ROOT_PATH } from '../paths';
import { cancelOffer, type Offer } from './apiHelpers';
import {
  sendGa4OfferUpdateAbortEvent,
  sendGa4OfferUpdateConfirmEvent,
} from './gaHelpers';
import styles from './UpdateOfferModal.scss';

interface UpdateOfferModalProps {
  offer: Offer;
  onClose: () => void;
}

export const UpdateOfferModal = ({ onClose, offer }: UpdateOfferModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setOfferCheckout } = useOfferCheckoutContext();
  const setError = useError();

  const { price: offerAmount } = offer;

  const handleCancel = () => {
    sendGa4OfferUpdateAbortEvent({
      date: offer.date,
      offerAmount: offer.price,
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      time: offer.time,
    });
    onClose();
  };

  const handleUpdateOfferAmount = (): void => {
    setIsLoading(true);
    sendGa4OfferUpdateConfirmEvent({
      date: offer.date,
      offerAmount: offer.price,
      restaurantName: offer.restaurant.name,
      publicName: offer.listing.publicName,
      time: offer.time,
    });

    void (async () => {
      try {
        const response = await cancelOffer(offer.id);

        if (isErrorResponse(response)) {
          setErrorMessage(response.message);
        } else {
          setOfferCheckout({
            date: offer.date,
            guestCount: offer.guestCount,
            listingId: offer.listing.id,
            restaurantId: offer.restaurant.id,
            publicName: offer.listing.publicName,
            time: offer.time,
          });
          navigate(OFFER_CHECKOUT_ROOT_PATH);
          onClose();
        }
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    })();
  };

  return (
    <Modal title="New Offer" onClose={handleCancel} isOpen>
      <div className={styles.container}>
        <div className={styles.info}>
          <p className={cx(typography.c2)}>
            Your initial offer payment method hold will be released and replaced
            by a new hold when you proceed with the below action “Make New
            Offer.” The initial hold will be released by your bank in 5–10
            business&nbsp;days.
          </p>
          <dl className={styles.currentOfferAmount}>
            <dt className={typography.c2}>Current Offer</dt>
            <dd className={typography.h2}>{centsToWholeDollar(offerAmount)}</dd>
          </dl>
        </div>
        <div className={styles.error}>{errorMessage}</div>
        <div className={styles.actions}>
          <Button
            className={styles.button}
            isLoading={isLoading}
            label="Cancel"
            onClick={handleCancel}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            className={styles.button}
            isLoading={isLoading}
            label="Make New Offer"
            onClick={handleUpdateOfferAmount}
            variant={ButtonVariants.Primary}
          />
        </div>
      </div>
    </Modal>
  );
};
