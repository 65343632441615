import {
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import cx from 'classnames';
import { useId, useState } from 'react';
import { Icon } from '../icon/Icon';
import styles from './Kebab.scss';

export interface KebabProps {
  className?: string;
  options?: KebabOption[];
}

export interface KebabOption {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  variant?: KebabOptionVariants;
}

export enum KebabOptionVariants {
  Pink = 'pink',
}

export const Kebab = ({ className, options = [] }: KebabProps) => {
  const menuId = useId();
  const [isOpen, setIsOpen] = useState(false);
  const { context, refs, floatingStyles } = useFloating({
    middleware: [shift()],
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left-start',
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
  ]);

  return (
    <div className={className}>
      <button
        aria-controls={menuId}
        aria-expanded={isOpen}
        aria-label="options"
        className={styles.button}
        ref={refs.setReference}
        type="button"
        {...getReferenceProps({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        })}
      >
        <Icon className={styles.icon} name="options" />
      </button>
      {isOpen && (
        <div
          className={styles.menu}
          id={menuId}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps({
            onClick: (e) => e.stopPropagation(),
          })}
        >
          {options.map((item) => (
            <button
              className={cx(styles.menuItem, {
                [styles.itemPink]: item.variant === KebabOptionVariants.Pink,
              })}
              disabled={item.disabled}
              key={JSON.stringify(item)}
              onClick={(e) => {
                e.preventDefault();
                item.onClick();
                setIsOpen(false);
              }}
              type="button"
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
