// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BalancePage__balanceContainer___sRMgF{background-color:var(--darkBlue);border-radius:8px;height:400px;max-width:560px;padding:40px;width:100%;text-align:center}@media only screen and (min-width: 720px){.BalancePage__balanceContainer___sRMgF{width:calc(100% - var(--sidePanelWidth))}}.BalancePage__balanceContainer___sRMgF .BalancePage__header___HhHdr{margin:0 0 48px}.BalancePage__balanceContainer___sRMgF .BalancePage__balance___pStic{margin:0 0 16px}.BalancePage__balanceContainer___sRMgF .BalancePage__text___YabSj{color:var(--white70);margin:0 0 48px}.BalancePage__actionContainer___aQpJZ{display:flex;justify-content:center;align-items:center;flex-direction:column}.BalancePage__disconnectBankAccountButton___E_Wd9{color:var(--gold);background:none;margin-top:12px}.BalancePage__disconnectBankAccountButton___E_Wd9:hover{text-decoration:none;color:var(--gold);background:none !important}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/BalancePage.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,uCACE,gCAAA,CACA,iBAAA,CACA,YAAA,CACA,eAAA,CACA,YAAA,CACA,UAAA,CACA,iBAAA,CCcE,0CDrBJ,uCAUI,wCAAA,CAAA,CAGF,oEACE,eAAA,CAGF,qEACE,eAAA,CAGF,kEACE,oBAAA,CACA,eAAA,CAIJ,sCACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CAGF,kDACE,iBAAA,CACA,eAAA,CACA,eAAA,CAEA,wDACE,oBAAA,CACA,iBAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balanceContainer": "BalancePage__balanceContainer___sRMgF",
	"header": "BalancePage__header___HhHdr",
	"balance": "BalancePage__balance___pStic",
	"text": "BalancePage__text___YabSj",
	"actionContainer": "BalancePage__actionContainer___aQpJZ",
	"disconnectBankAccountButton": "BalancePage__disconnectBankAccountButton___E_Wd9"
};
export default ___CSS_LOADER_EXPORT___;
