import { createAction } from '@shared/context/reducerUtils';
import { todayAsShortLocal } from '@shared/utils/date';
import type { Availability } from 'webReservations/restaurant/apiHelpers';
import type { TSelectedAvailability } from './AvailabilityContext';
import { getFirstAvailableTime } from './availabilityDerivedState';

export interface AvailabilityState {
  availabilities: Availability[];
  selectedGuestCount: number;
  selectedTime: string | null;
  selectedDate: string;
  showEarlierOffset: number;
  showLaterOffset: number;
  selectedFloorPlanListingIds: string[];
  selectedAvailability: TSelectedAvailability | null;
}

export const initialAvailabilityState: () => AvailabilityState = () => ({
  availabilities: [],
  selectedGuestCount: 2,
  selectedTime: null,
  selectedDate: todayAsShortLocal(),
  showEarlierOffset: 0,
  showLaterOffset: 0,
  selectedAvailability: null,
  selectedFloorPlanListingIds: [],
});

enum AvailabilityActionType {
  SetAvailabilities = 'SetAvailabilities',
  SetSelectedAvailability = 'SetSelectedAvailability',
  ClearSelectedAvailability = 'ClearSelectedAvailability',
  SetSelectedGuestCount = 'SetSelectedGuestCount',
  SetSelectedTime = 'SetSelectedTime',
  SetSelectedDate = 'SetSelectedDate',
  IncrementShowEarlierOffset = 'IncrementShowEarlierOffset',
  IncrementShowLaterOffset = 'IncrementShowLaterOffset',
  SetSelectedFloorPlanListingIds = 'SetSelectedFloorPlanListingIds',
}

export const setAvailabilities = (availabilities: Availability[]) =>
  createAction(AvailabilityActionType.SetAvailabilities, { availabilities });

export const setSelectedAvailability = (
  selectedAvailability: TSelectedAvailability,
) =>
  createAction(AvailabilityActionType.SetSelectedAvailability, {
    selectedAvailability,
  });

export const clearSelectedAvailability = () =>
  createAction(AvailabilityActionType.ClearSelectedAvailability, null);

export const setSelectedGuestCount = (guestCount: number) =>
  createAction(AvailabilityActionType.SetSelectedGuestCount, {
    guestCount,
  });

export const setSelectedTime = (selectedTime: string) =>
  createAction(AvailabilityActionType.SetSelectedTime, {
    selectedTime,
  });

export const setSelectedDate = (selectedDate: string) =>
  createAction(AvailabilityActionType.SetSelectedDate, {
    selectedDate,
  });

export const incrementShowEarlierOffset = () =>
  createAction(AvailabilityActionType.IncrementShowEarlierOffset, null);

export const incrementShowLaterOffset = () =>
  createAction(AvailabilityActionType.IncrementShowLaterOffset, null);

export const setSelectedFloorPlanListingIds = (
  selectedFloorPlanListingIds: string[],
) =>
  createAction(AvailabilityActionType.SetSelectedFloorPlanListingIds, {
    selectedFloorPlanListingIds,
  });

export const availabilityReducer = (
  state: AvailabilityState,
  action:
    | ReturnType<typeof setAvailabilities>
    | ReturnType<typeof setSelectedAvailability>
    | ReturnType<typeof clearSelectedAvailability>
    | ReturnType<typeof setSelectedGuestCount>
    | ReturnType<typeof setSelectedTime>
    | ReturnType<typeof setSelectedDate>
    | ReturnType<typeof incrementShowEarlierOffset>
    | ReturnType<typeof incrementShowLaterOffset>
    | ReturnType<typeof setSelectedFloorPlanListingIds>,
): AvailabilityState => {
  switch (action.type) {
    case AvailabilityActionType.SetAvailabilities:
      return {
        ...state,
        availabilities: action.payload.availabilities,
        selectedTime:
          state.selectedTime ||
          getFirstAvailableTime({
            ...state,
            availabilities: action.payload.availabilities,
          }),
      };
    case AvailabilityActionType.SetSelectedAvailability:
      return {
        ...state,
        selectedAvailability: action.payload.selectedAvailability,
      };
    case AvailabilityActionType.ClearSelectedAvailability:
      return {
        ...state,
        selectedAvailability: null,
      };
    case AvailabilityActionType.SetSelectedGuestCount:
      return {
        ...state,
        selectedGuestCount: action.payload.guestCount,
        showEarlierOffset: 0,
        showLaterOffset: 0,
        selectedAvailability: null,
      };
    case AvailabilityActionType.SetSelectedTime:
      return {
        ...state,
        selectedTime: action.payload.selectedTime,
        showEarlierOffset: 0,
        showLaterOffset: 0,
        selectedAvailability: null,
      };
    case AvailabilityActionType.SetSelectedDate:
      return {
        ...state,
        selectedDate: action.payload.selectedDate,
        showEarlierOffset: 0,
        showLaterOffset: 0,
        selectedAvailability: null,
      };
    case AvailabilityActionType.IncrementShowEarlierOffset:
      return {
        ...state,
        showEarlierOffset: state.showEarlierOffset + 20,
      };
    case AvailabilityActionType.IncrementShowLaterOffset:
      return {
        ...state,
        showLaterOffset: state.showLaterOffset + 20,
      };
    case AvailabilityActionType.SetSelectedFloorPlanListingIds:
      return {
        ...state,
        selectedFloorPlanListingIds: action.payload.selectedFloorPlanListingIds,
      };
    default:
      return state;
  }
};
