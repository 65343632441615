import cx from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Icon } from '@components/icon/Icon';
import { Kebab } from '@components/kebab/Kebab';
import { DeprecatedNarrowView } from '@components/narrowView/DeprecatedNarrowView';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import { isoToShortDate } from '@utils/date';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import { ImageLoader } from '../components/ImageLoader';
import { useTimer } from '../hooks/useTimer';
import { ReservationCard } from '../reservations/ReservationCard';
import { formatTimeRemaining } from '../utils/formatTimeRemaining';
import type { Offer } from './apiHelpers';
import {
  sendGa4OfferUpdateStartEvent,
  sendGa4OfferWithdrawStartEvent,
} from './gaHelpers';
import styles from './OfferCard.scss';
import { UpdateOfferModal } from './UpdateOfferModal';
import { WithdrawOfferModal } from './WithdrawOfferModal';

const NAVIGATE_DELTA_TO_REFRESH_PAGE = 0;

export interface OfferOutCardProps {
  offer: Offer;
}

export const OfferOutCard = ({ offer }: OfferOutCardProps) => {
  const { date, guestCount, id, price, restaurant, listing, time } = offer;
  const {
    isOpen: isWithdrawOfferModalOpen,
    open: openWithdrawOfferModal,
    close: closeWithdrawOfferModal,
  } = useIsOpen();
  const {
    isOpen: isUpdateOfferModalOpen,
    open: openUpdateOfferModal,
    close: closeUpdateOfferModal,
  } = useIsOpen();
  const timeRemaining = useTimer(offer);
  const navigate = useNavigate();

  const linkTo = `/offers/${id}`;

  const handleUpdateOfferClick = () => {
    sendGa4OfferUpdateStartEvent({
      date,
      offerAmount: price,
      publicName: listing.publicName,
      restaurantName: restaurant.name,
      time,
    });
    openUpdateOfferModal();
  };
  const handleWithdrawOfferClick = () => {
    sendGa4OfferWithdrawStartEvent({
      date,
      offerAmount: price,
      publicName: listing.publicName,
      restaurantName: restaurant.name,
      time,
    });
    openWithdrawOfferModal();
  };

  return (
    <>
      <DeprecatedNarrowView
        element={
          <li className={styles.offersOutMobileContainer}>
            <Link className={styles.linkContainer} to={linkTo}>
              <div className={styles.topRow}>
                <ImageLoader
                  className={styles.image}
                  src={`${process.env.RESTAURANT_IMAGE_BASE_URL}/${restaurant.id}/main.jpg`}
                />
                <div className={styles.reservationAttributes}>
                  <div className={styles.header}>
                    <div className={cx(typography.d5, styles.name)}>
                      {restaurant.name}
                    </div>
                    <Kebab
                      data-testid="kebab"
                      className={styles.kebabButton}
                      options={[
                        {
                          label: 'Update Offer',
                          onClick: handleUpdateOfferClick,
                        },
                        {
                          label: 'Withdraw Offer',
                          onClick: handleWithdrawOfferClick,
                        },
                      ]}
                    />
                  </div>
                  <div className={cx(typography.t2, styles.attribute)}>
                    <Icon className={styles.mobileIcon} name="calendar" />
                    {`${isoToShortDate(date)} - ${ISOTimeTo12HourTime(time)}`}
                  </div>
                  <div className={cx(typography.t2, styles.attribute)}>
                    <Icon className={styles.mobileIcon} name="table" />
                    {listing.publicName}
                  </div>
                  <div className={cx(typography.t2, styles.attribute)}>
                    <Icon className={styles.mobileIcon} name="couple" />
                    {guestCount}
                  </div>
                </div>
              </div>
              <div className={styles.offerBox}>
                <div className={cx(typography.t2, styles.expiration)}>
                  <Icon className={styles.icon} name="timer" /> Offer expires
                  {timeRemaining && (
                    <span>{formatTimeRemaining(timeRemaining)}</span>
                  )}
                </div>
                <div className={cx(typography.t2, styles.price)}>
                  {centsToWholeDollar(price)}
                </div>
              </div>
            </Link>
          </li>
        }
        fallback={
          <ReservationCard
            date={date}
            guestCount={guestCount}
            linkTo={linkTo}
            restaurant={{
              name: restaurant.name,
              id: restaurant.id,
              description: '',
              address: '',
              phone: '',
              website: '',
            }}
            listingPublicName={listing.publicName}
            time={time}
          >
            <div className={styles.actions}>
              <div className={cx(typography.h2)}>
                {centsToWholeDollar(price)}
              </div>
              <div className={cx(typography.t1, styles.expiration)}>
                <Icon className={styles.icon} name="timer" />
                {timeRemaining && formatTimeRemaining(timeRemaining)}
              </div>
              <div className={styles.actionButtonsContainer}>
                <Button
                  label="Update"
                  onClick={handleUpdateOfferClick}
                  useDeprecatedBreakpoint
                  variant={ButtonVariants.Default}
                />
                <Button
                  label="Withdraw"
                  onClick={handleWithdrawOfferClick}
                  useDeprecatedBreakpoint
                  variant={ButtonVariants.Default}
                />
              </div>
            </div>
          </ReservationCard>
        }
      />
      {isUpdateOfferModalOpen && (
        <UpdateOfferModal offer={offer} onClose={closeUpdateOfferModal} />
      )}
      {isWithdrawOfferModalOpen && (
        <WithdrawOfferModal
          closeModal={closeWithdrawOfferModal}
          offer={offer}
          onSubmit={() => navigate(NAVIGATE_DELTA_TO_REFRESH_PAGE)}
        />
      )}
    </>
  );
};
