// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Kebab__button___c8oQL{background-color:rgba(0,0,0,0);border-radius:.25rem;border:none;cursor:pointer}.Kebab__icon___lv5dM{fill:var(--primary200)}.Kebab__menu___Msjr_{background-color:var(--darkGrey300);border-radius:.25rem;box-shadow:0 .25rem .5rem #000;padding:.5rem 0;width:min-content}.Kebab__menuItem___yu6mQ{background-color:rgba(0,0,0,0);border:0;color:var(--white);cursor:pointer;white-space:nowrap;padding:.875rem 1.5rem;text-align:left;user-select:none;width:100%}.Kebab__menuItem___yu6mQ:active,.Kebab__menuItem___yu6mQ:focus,.Kebab__menuItem___yu6mQ:hover{background-color:var(--primary400)}.Kebab__menuItem___yu6mQ:disabled{color:var(--darkGrey200);cursor:not-allowed}.Kebab__itemPink___Dx1Gn{color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/shared/components/kebab/Kebab.scss"],"names":[],"mappings":"AAAA,uBACE,8BAAA,CACA,oBAAA,CACA,WAAA,CACA,cAAA,CAGF,qBACE,sBAAA,CAGF,qBACE,mCAAA,CACA,oBAAA,CACA,8BAAA,CACA,eAAA,CACA,iBAAA,CAGF,yBACE,8BAAA,CACA,QAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA,CACA,UAAA,CAEA,8FAGE,kCAAA,CAGF,kCACE,wBAAA,CACA,kBAAA,CAIJ,yBACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Kebab__button___c8oQL",
	"icon": "Kebab__icon___lv5dM",
	"menu": "Kebab__menu___Msjr_",
	"menuItem": "Kebab__menuItem___yu6mQ",
	"itemPink": "Kebab__itemPink___Dx1Gn"
};
export default ___CSS_LOADER_EXPORT___;
