import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { ACCOUNT_BALANCE_PATH } from 'webReservations/paths';
import typography from '~styles/typography.scss';
import { deleteBankAccount } from './apiHelpers';
import styles from './DisconnectBankAccountModal.scss';

interface DisconnectBankAccountModalProps {
  closeModal: () => void;
}

export const DisconnectBankAccountModal = ({
  closeModal,
}: DisconnectBankAccountModalProps) => {
  const [isDisconnectBankButtonDisabled, setIsDisconnectBankButtonDisabled] =
    useState(false);

  const navigate = useNavigate();

  const handleOnClickDisconnectBankAccount = async () => {
    setIsDisconnectBankButtonDisabled(true);
    const disconnectResponse = await deleteBankAccount();
    closeModal();
    navigate(ACCOUNT_BALANCE_PATH, {
      state: {
        plaidLinkError: isErrorResponse(disconnectResponse),
        attemptedToDisconnectBankAccount: true,
      },
    });
  };

  return (
    <Modal title="Disconnect Bank Account" isOpen onClose={closeModal}>
      <div className={styles.container}>
        <p className={typography.c2}>
          Warning: Any outstanding funds transfers will prevent disconnecting
          this account. Please confirm that those transfers are complete before
          disconnecting.
        </p>
        <p>Are you sure you would like to remove your bank account?</p>
        <ModalActions>
          <Button
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            isDisabled={isDisconnectBankButtonDisabled}
            label="Disconnect Bank"
            onClick={handleOnClickDisconnectBankAccount}
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </div>
    </Modal>
  );
};
