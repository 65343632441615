import cx from 'classnames';
import { Kebab, KebabOptionVariants } from '@components/kebab/Kebab';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import { DeletePaymentMethodModal } from './DeletePaymentMethodModal';
import styles from './SavedPaymentMethod.scss';

const CC_EXP_MONTH_TARGET_LENGTH = 2;
const CC_EXP_MONTH_PAD_STRING = '0';
const CC_EXP_YEAR_CHARS_TO_SLICE_FROM_END = -2;

interface SavedPaymentMethodProps {
  savedPaymentMethod: {
    brand: string;
    expirationMonth: number;
    expirationYear: number;
    id: string;
    last4: string;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  selectedPaymentMethodId?: string;
}

export const SavedPaymentMethod = ({
  onChange,
  onDelete,
  savedPaymentMethod: { brand, expirationMonth, expirationYear, id, last4 },
  selectedPaymentMethodId,
}: SavedPaymentMethodProps) => {
  const {
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useIsOpen();
  return (
    <section aria-labelledby={`${id}-label`} className={styles.main} key={id}>
      <label className={styles.paymentMethod} htmlFor={id} id={`${id}-label`}>
        <span>
          <input
            checked={id === selectedPaymentMethodId}
            id={id}
            name="paymentMethod"
            onChange={onChange}
            type="radio"
            value={id}
          />
          <span className={cx(typography.t1, styles.brand)}>{brand}</span>
        </span>
        <span className={typography.t1}>
          <span aria-hidden="true">************</span>
          {last4}
        </span>
        <span className={cx(typography.t1, styles.expiration)}>
          Expires{' '}
          {`${expirationMonth
            .toString()
            .padStart(
              CC_EXP_MONTH_TARGET_LENGTH,
              CC_EXP_MONTH_PAD_STRING,
            )}/${expirationYear
            .toString()
            .slice(CC_EXP_YEAR_CHARS_TO_SLICE_FROM_END)}`}
        </span>
      </label>
      <Kebab
        options={[
          {
            label: 'Delete',
            onClick: openDeleteModal,
            variant: KebabOptionVariants.Pink,
          },
        ]}
      />
      {isDeleteModalOpen && (
        <DeletePaymentMethodModal
          isOpen
          onClose={closeDeleteModal}
          onConfirm={onDelete}
        />
      )}
    </section>
  );
};
