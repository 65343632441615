import { Button, ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { PLAID_AUTH_PATH } from 'webReservations/paths';
import typography from '~styles/typography.scss';
import styles from './ConnectBankAccountModal.scss';

interface ConnectBankAccountModalProps {
  closeModal: () => void;
}

export const ConnectBankAccountModal = ({
  closeModal,
}: ConnectBankAccountModalProps) => (
  <Modal title="Connect Bank Account" isOpen onClose={closeModal}>
    <div className={styles.container}>
      <p className={typography.c2}>
        By connecting your bank account and transferring your money out of Peak,
        you agree to the following:
      </p>
      <ul>
        <li>
          <a className={typography.h7} href="https://stripe.com/legal">
            Stripe Services Agreement
          </a>
        </li>
        <li>
          <a
            className={typography.h7}
            href="https://stripe.com/connect-account/legal/recipient"
          >
            Stripe Recipient Agreement
          </a>
        </li>
      </ul>
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <LinkStyledAsButton
          label="I Agree"
          variant={ButtonVariants.Primary}
          to={PLAID_AUTH_PATH}
          onClick={closeModal}
        />
      </ModalActions>
    </div>
  </Modal>
);
