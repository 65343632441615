import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import { SetListPriceModal } from 'webReservations/reservations/SetListPriceModal';
import { UpdateListPriceModal } from 'webReservations/reservations/UpdateListPriceModal';
import typography from '~styles/typography.scss';
import styles from './ListPrice.scss';

interface ListPriceProps {
  fetchReservation: () => void;
  price: number | null;
  reservationId: string;
}

export const ListPrice = ({
  fetchReservation,
  price,
  reservationId,
}: ListPriceProps) => {
  const {
    isOpen: isSetListPriceModalOpen,
    open: openSetListPriceModal,
    close: closeSetListPriceModal,
  } = useIsOpen();
  const {
    isOpen: isUpdateListingPriceModalOpen,
    open: openUpdateListingPriceModal,
    close: closeUpdateListingPriceModal,
  } = useIsOpen();

  return price ? (
    <>
      <button
        className={styles.updateListPrice}
        onClick={openUpdateListingPriceModal}
      >
        <h3 className={typography.h7m}>Update List Price</h3>
        <div className={cx(typography.h7m, styles.listPriceAmount)}>
          {centsToWholeDollar(price)}
        </div>
      </button>
      <UpdateListPriceModal
        closeModal={closeUpdateListingPriceModal}
        isOpen={isUpdateListingPriceModalOpen}
        listPrice={price}
        onSubmit={fetchReservation}
        reservationId={reservationId}
      />
    </>
  ) : (
    <>
      <button className={styles.setListPrice} onClick={openSetListPriceModal}>
        <div className={styles.instructionsContainer}>
          <h3 className={typography.h7m}>List Reservation</h3>
          <p className={typography.t2}>
            Set a sales price and we’ll handle the rest.
          </p>
        </div>
        <Icon name="chevron" />
      </button>
      {isSetListPriceModalOpen && (
        <SetListPriceModal
          closeModal={closeSetListPriceModal}
          onSubmit={fetchReservation}
          reservationId={reservationId}
        />
      )}
    </>
  );
};
