import * as Sentry from '@sentry/react';
import isNetworkError from 'is-network-error';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ApiServerError } from '@shared/api/errors';
import type { Environment } from '../environments';
import { PRODUCTION, STAGING } from '../environments';

const shouldReportError = (error: unknown) => {
  if (isNetworkError(error) || isSafariNetworkError(error)) return false;
  if (error instanceof ApiServerError) return false;
  return true;
};

// workaround for https://github.com/sindresorhus/is-network-error/issues/7
const isSafariNetworkError = (e: unknown) =>
  e instanceof TypeError && e.message === 'Load failed';

const initSentry = (): void => {
  const sentryEnv = process.env.SENTRY_ENVIRONMENT as Environment;

  if ([STAGING, PRODUCTION].includes(sentryEnv || '')) {
    Sentry.init({
      beforeSend(event, hint) {
        if (
          hint.originalException &&
          !shouldReportError(hint.originalException)
        )
          return null;
        return event;
      },
      dsn: process.env.SENTRY_DSN,
      environment: process.env.SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.extraErrorDataIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
};

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default initSentry;
