import type { ApiResponse } from '@shared/types/apiHelpers';
import { api } from '../api';
import API from '../api/apiClient';

interface CreateReservationRequest {
  date: string;
  guestCount: number;
  listingId: string;
  paymentMethodId: string | null;
  purchasePrice: number;
  setupFutureCardUsage?: boolean;
  time: string;
  cancellationPolicyId: string | null;
}

export interface CreatedReservation {
  fee: number;
  id: string;
  price: number;
  receiptNumber: string;
  tax: number;
  total: number;
  type: 'initial purchase';
}

export const bookReservation = async (
  reservationDetails: CreateReservationRequest,
): Promise<CreatedReservation> => api.post(`/reservations`, reservationDetails);

interface CreateOfferRequest {
  date: string;
  expectedCancellationPolicyId: string | null;
  guestCount: number;
  listingId: string;
  offerAmount: number;
  paymentMethodId: string;
  setupFutureCardUsage: boolean;
  time: string;
}

export interface CreatedOffer {
  fee: number;
  id: string;
  price: number;
  tax: number;
  total: number;
}

export const createOffer = async (
  payload: CreateOfferRequest,
): Promise<ApiResponse<CreatedOffer>> => {
  const response = await API.post('/offers', payload);

  return response.json();
};

export const deletePaymentMethod = async (id: string): Promise<boolean> => {
  const response = await API.delete(`/payment-methods/${id}`);
  return response.ok;
};

export interface PaymentMethod {
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  id: string;
  last4: string;
}

export const getPaymentMethods = async (): Promise<
  ApiResponse<PaymentMethod[]>
> => {
  const response = await API.get('/payment-methods');

  return response.json();
};
